import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { Nullable } from '@/core/interfaces/common';

interface SwitchesContextShape {
  activeDrawerOption: number;
  drawerPMSearchValue: string;
  drawerProjectSearchValue: string;
  setActiveDrawerOption: Dispatch<SetStateAction<number>>;
  setDrawerPMSearchValue: Dispatch<SetStateAction<string>>;
  setDrawerProjectSearchValue: Dispatch<SetStateAction<string>>;
  setShowObservedProjects: Dispatch<SetStateAction<boolean>>;
  setTab: Dispatch<SetStateAction<Nullable<number>>>;
  showObservedProjects: boolean;
  tab: Nullable<number>;
}

export const DrawerContext = React.createContext<SwitchesContextShape>({
  activeDrawerOption: 0,
  drawerPMSearchValue: '',
  drawerProjectSearchValue: '',
  setActiveDrawerOption: () => null,
  setDrawerPMSearchValue: () => null,
  setDrawerProjectSearchValue: () => null,
  setShowObservedProjects: () => null,
  setTab: () => null,
  showObservedProjects: false,
  tab: null,
});

// eslint-disable-next-line react/prop-types
export const DrawerContextProvider: React.FC = ({ children }) => {
  const [tab, setTab] = useState<Nullable<number>>(0);
  const [activeDrawerOption, setActiveDrawerOption] = useState<number>(0);
  const [showObservedProjects, setShowObservedProjects] = useState<boolean>(false);
  const [drawerPMSearchValue, setDrawerPMSearchValue] = useState<string>('');
  const [drawerProjectSearchValue, setDrawerProjectSearchValue] = useState<string>('');

  return (
    <DrawerContext.Provider
      value={{
        activeDrawerOption,
        drawerPMSearchValue,
        drawerProjectSearchValue,
        setActiveDrawerOption,
        setDrawerPMSearchValue,
        setDrawerProjectSearchValue,
        setShowObservedProjects,
        setTab,
        showObservedProjects,
        tab,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = () => useContext(DrawerContext);
